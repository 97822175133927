@import url('https://fonts.googleapis.com/css?family=Cabin|Herr+Von+Muellerhoff|Source+Sans+Pro:400,900&display=swap');
/*Global styles*/
*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-font: 'Source Sans Pro', sans-serif;
    --secondary-font: 'Herr Von Muellerhoff', cursive;
    --body-font: 'Cabin', sans-serif;
    --main-font-color-dark: #00000042;
    --secondary-font-color: #a18861;
    --body-font-color: #515151;
}


html {

    font-family: var(--body-font);
    font-size: 10px;
    color:   var(--body-font-color);
    scroll-behavior: smooth;

}

body {
    overflow-x: hidden;


}
section {

    padding: 3.9rem 0;
    overflow: hidden;

}
img {
    width: 100%;
    max-width: 100%;
}
a {
text-decoration: none;

}
p {
    font-size: 1.6rem;

}
.container {

    width: 100%;
    max-width: 122.5rem;
    margin: 0 auto;
    padding: 0 2.4rem;
}

/*Header style */

header  {
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(0,0,0,.5),transparent)





}

.fas.fa-times {

    display: none;
}
nav  {
    height: 7.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.menu-toggle {

    color: white;
    font-size: 2.2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.5rem;
    cursor: pointer;
    z-index: 1500;


}



.nav-list {

    list-style: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100vh;
    background-color:  var(--main-font-color-dark);
    padding: 4.4rem ;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1250;
    transform: translateX(-100%);
    transition:  .5s;


}

.nav::before {

    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
    z-index: 1000;
    opacity: 0;
    transform: scale(0);
    transition: opacity .5s;



}

.open .fa-times {

    display: block;
}

.open .fa-bars {

    display: none;
}


.open .nav-list {

    transform: translateX(0);
}
.open .nav::before  {

    opacity: 1;
    transform:scale(1);


}
 



  .nav-items  {

    border-bottom: 2px solid rgba(255,255,255,.3);

  }
  .nav-link  {
      display: block;
      color: #fff;
      text-transform: uppercase;
      font-size:1.6rem;
      letter-spacing: 2px;
      margin-right: -2px;
  }

.nav-link:hover{
    color: var(--secondary-font-color);



}
/*Hero ends here*/
.hero   {

    width: 100%;
    height: 100vh;
    background-image: url("https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/hero.jpg?raw=true");
    background-size: cover;
    display: flex;
    align-items: center;
    text-align: center;


}

.sub-headline {

    font-size: 8.5rem;
    font-family: var(--secondary-font);
    color: var(--secondary-font-color);
    font-weight: 100;
    line-height: .4;
    letter-spacing: 2px;
    opacity: 0;
    animation: fadeUp .5s forwards;
    animation-delay: .5s ;


}


.first-letter {

    text-transform: uppercase;
    font-size: 10.3rem;
}

.headline {
color:white;
font-size: 3.7rem;
font-family:  var(--main-font);
text-transform: uppercase;
font-weight: 900;
letter-spacing: .5rem;
margin-right: -.5rem;
animation: scale .5s forwards;

}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;

   
}

.line {
    width: 100%;
max-width: 8.4rem;
height: .25rem;
background-color: #fff;
position: relative;
animation: grow 2s forwards;
animation-delay: .9s;
opacity: 0;


}
.line-right::before,
.line-left::before  {
content: ' ';
position: absolute;
top: 50%;
transform: translateY(-50%);
border: .6rem solid transparent;
animation: grow .5s forwards;
animation-delay: 1.2s;
opacity: 0;



}

.line-left::before  {

    border-left-color: rgb(255, 255, 255);
    right: 0;
}
.line-right::before  {

    border-right-color: rgb(255, 255, 255);
    right: 0;
}
.asterisk {

    font-size: 1.2rem;
    color:  var(--secondary-font-color);
    margin:0 1.6rem;
    opacity: 0; 
   animation: spin  .5s forwards;
   animation-delay: .7s;
    
}

.single-animation {
    opacity: 0;
    animation: fadeDown .5s forwards;
    animation-delay: 1.5s ;


}


.headline-description h5 {

color: white;
font-size: 1.4rem;
font-weight: 100;
text-transform: uppercase;
margin-bottom: 1.2rem;
letter-spacing:3px;
margin-right: -3px;

}
.btn {

    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: -2px;

}

.cta-btn {

font-size: 1.1rem;
background-color: white;
padding: .9rem 1.8rem;
color:  var(--body-font-color);
border-radius: .4rem;

}

.cta-btn:hover,
.cta-btn:focus {

    color: white;
    background-color: var(--secondary-font-color);

}

/*Discover our story section */
.global-headline {
    text-align: center;
    margin-top: 3.9rem;



}

.discover-our-story .global-headline {

    margin-top: 6.9rem;
}

.global-headline .asterisk {

    color: var(--body-font-color);
    margin: 2.4rem 0;
}

.headline-dark {

    color: var(--main-font-color-dark);
}

.global-headline .sub-headline {

    letter-spacing: -.1px;
    line-height: .42;
}


.restaurant-info {
    text-align: center;
}

.restaurant-description  {
    line-height: 1.6;
    margin-bottom: 2.4rem;


}

.body-btn {
    font-size: 1.5rem;
    color: var(--secondary-font-color);
    position: relative;  
}
.body-btn::before {

    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    bottom: -3px;
    background-color: var(--secondary-font-color);
}

.body-btn:hover , .body-btn:focus {
    color: var(--main-font-color-dark);


}
.body-btn:hover::before, .body-btn:focus::before {

background-color: var(--main-font-color-dark);

}

/*Tasteful recipes */




.tasteful-recipes {
background: url("https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/tasteful-recipes.jpg?raw=true");
background-size: cover;


}
.between {

    min-height: 65vh;
    display: flex;
    align-items: center;
}

/*Discover Our Menu*/

.image-group  {

    display: grid;
    grid-template-columns:  repeat(2, 1fr);
    grid-gap: 1.5rem;
}


.discover-our-menu .restaurant-description {

    padding-top: 3rem;
    margin-bottom: 0;
}

/*The perfect blend */

.perfect-blend {

    background: url("https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/perfect-blend.jpg?raw=true") center no-repeat ;

    background-size:cover;
    
}

/*footer */
footer {

    padding: 7.9rem;
    background-color: #121212;
    color: #fff;
text-align: center;
position: relative;
}

.back-to-top {

    width: 7rem;
    height: 7rem;
    background-color: #121212;
    position: absolute;
    top:-3rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;

}

.back-to-top i {
display: block;
color: white;
font-size: 2rem;
padding:2rem;
animation: Up 2s infinite;

}
.footer-content {
overflow: hidden;

}
.footer-content h4 {

    font-size: 1.9rem;
    text-transform: uppercase;
    letter-spacing: .5rem;
    font-weight: 100;
    margin-right: -.5rem;

}


.footer-content .asterisk {

    margin: 2.4rem 0;
    
}
.footer-content-about {

    margin-bottom: 5.4rem;


}
.footer-content-about p{

   line-height: 2;


}
.social-icons {

    list-style: none;
    margin-bottom: 5.4rem;
    display: flex;
    justify-content: center;
}
.social-icons i {

    font-size: 2rem;
    color: white;
    padding: 2.8rem 2rem;
    opacity: .5;
}
.social-icons i:hover,
.social-icons i:focus {
    color: var(--secondary-font-color)



}

.newsletter-form {

    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

}
.newsletter-input {

    width: 100%;
    max-width: 25rem;
    padding:1rem;
    border-radius: .4rem;

}
.newsletter-btn {
    background-color: transparent;
    border: none;
    color: var(--main-font-color-dark);
    cursor: pointer;
    font-size: 1.6rem;
    padding: 1px .6rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10.5rem;


}
/*media queries  */

@media screen and (min-width:900px) {
section {
    padding:7.9rem;

}
.fas.fa-times ,  .fa-times {

    display: none;
}
.nav::before {
    display: none;
}
.nav {
    justify-content: space-between;
}

.nav-list  {

    position: initial;
    width: initial;
    height: initial;
    background-color: transparent;
    padding: 0;
    justify-content: initial;
    flex-direction: row;
    transform: initial;
    transition: initial;



}

.nav-item {

    margin: 0  2.4rem;
    border: none;
    


}
.nav-item:last-child {

    margin: 0  ;
    


}
.nav-link {

    font-size: 1.3rem;
    


}
.active {

    position: relative;
    


}
.active::before {

content: '';    
position: absolute;
width: 100%;
height: 2px;
background-color: white;
left: 0;
bottom: -3px;




}
.sub-headline {

font-size: 10rem;    


}
.first-letter {

    font-size: 12.2rem;    
    


}
.headline {

    font-size: 4.7rem;  
    letter-spacing: .8rem;  
    


}
.line {

max-width: 11.4rem;    


}

.restaurant-info {

display: flex;   
align-items: center; 
    
    }

.restaurant-info >div {

flex: 1;        
        
        }

 .padding-right {
padding-right:  7rem;            
            
   }

    .footer-content {

  max-width: 77.4rem;   
  margin: auto; 
                
 }

.footer-content-about {

 max-width: 51.4rem; 
margin: 0 auto 5.4rem;
                    
                    
   }
 .footer-content-divider {
   display: flex;
 justify-content:space-between ;
                        
                        
 }
 .social-media, .newsletter-form {
     width: 100%;
     max-width:  27.3rem; ;
     margin: 0 1rem;

}
.social-icons i {
    opacity: 1;

}
.newsletter-btn  {
margin-left: 7.5rem ;

}

}

/*Animmationss*/

@keyframes fadeUp {

0% {
    transform: translateY(4rem);



}
100% {
    opacity: 1;
    transform: translateY(0) ;

}
}


@keyframes scale {

    0% {
        transform: scale(2 );
    
    
    
    }
    100% {
        opacity: 1;
    transform: scale(1) ;
    
    }
    }
    

    @keyframes grow {

        0% {
        width: 0;;       
        
        
        }
        100% {
            opacity: 1;
            width: 100%;        }
        }


         @keyframes spin{

        0% {
transform: rotate(0);        
        
        }
        100% {
            opacity: 1;
            transform: rotate(-360deg);        
        }
        }


        @keyframes fadeDown {

            0% {
                transform: translateY(-1rem);
            
            
            
            }
            100% {
                opacity: 1;
                transform: translateY(0) ;
            
            }
            }

            @keyframes Up {

                0% {
            opacity: 0;     
                }
            
            50% {
                opacity: 1;
            }
                
                
                
             100% {
              opacity: 0;
              transform: translateY(-1rem) ;
                
                }
                }

         